<template>
  <b-card-code no-body title="Asset Transaction History">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
        <div class="row">

          <div class="col-12" v-if="checkPermission('add commodity transaction')">
            <b-button v-b-modal.form-transaction class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add/Withdraw Asset">
              <plus-circle-icon size="1x" class="custom-class"></plus-circle-icon> Add/Withdraw User Asset Balance
            </b-button>

            <b-modal no-close-on-backdrop id="form-transaction" title="Add/Withdraw Asset Balance">
              <div class="form">

                <div class="form-group">
                  <label for="">Type:</label>
                  <select class="form-control" v-model="formPayload.type">
                    <option value="add">Add</option>
                    <option value="deduct">Withdraw</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">User:</label>
                  <v-select
                      v-model="formPayload.user_id"
                      :options="users"
                      :reduce="option => option.id"
                      @search="onSearchUser"
                    >
                      <template slot="no-options">
                        Type user name, code, or email..
                      </template>
                    </v-select>
                </div>

                <div class="form-group">
                      <label>Asset</label>
                      <select class="form-control" v-model="formPayload.commodity_slug">
                        <option selected value="">-- Select Asset --</option>
                        <option v-for="commodity in commodities" v-bind:key="commodity.slug" v-bind:value="commodity.slug">{{ commodity.name }}</option>
                      </select>
                  </div>
                  <div class="form-group">
                      <label>Depository</label>
                      <select class="form-control" v-model="formPayload.warehouse_slug">
                        <option selected value="">-- Select Depository --</option>
                        <option v-for="warehouse in warehouses" v-bind:key="warehouse.slug" v-bind:value="warehouse.slug">{{ warehouse.name }}</option>
                      </select>
                  </div>

                <div class="form-group">
                  <label for="">Amount:</label>
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="formPayload.amount">
                    <div class="input-group-append" v-if="formPayload.commodity_slug">
                      <span class="input-group-text">{{ units[formPayload.commodity_slug] }}</span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Note: (Optional)</label>
                  <textarea class="form-control" v-model="formPayload.note"></textarea>
                </div>

              </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button variant="success" @click="addDeductCommodity()" v-bind:disabled="isLoading">
                  Submit
                </b-button>
              </template>
            </b-modal>
          </div>

          <div class="col-12">
              <div class="form-row mb-1">
                  <div class="col-lg-4">
                      <label>Filter by User</label>
                      <v-select
                        v-model="filter.user_id"
                        :options="users"
                        :reduce="option => option.id"
                        @search="onSearchUser"
                      >
                        <template slot="no-options">
                          Type user name, code, or email..
                        </template>
                      </v-select>
                  </div>
                  <div class="col-lg-4">
                      <label>Filter by Asset</label>
                      <select class="form-control form-control-sm" v-model="filter.commodity_slug">
                        <option selected value="">-- All Asset --</option>
                        <option v-for="commodity in commodities" v-bind:key="commodity.slug" v-bind:value="commodity.slug">{{ commodity.name }}</option>
                      </select>
                  </div>
                  <div class="col-lg-4">
                      <label>Filter by Depository</label>
                      <select class="form-control form-control-sm" v-model="filter.warehouse_slug">
                        <option selected value="">-- All Depository --</option>
                        <option v-for="warehouse in warehouses" v-bind:key="warehouse.slug" v-bind:value="warehouse.slug">{{ warehouse.name }}</option>
                      </select>
                  </div>
              </div>
          </div>

        </div>
    </div>

    <commodity-transaction-table v-if="showTable" :user_id="filter.user_id" :warehouse_slug="filter.warehouse_slug" :commodity_slug="filter.commodity_slug"></commodity-transaction-table>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import { PlusCircleIcon } from 'vue-feather-icons'
import CommodityTransactionTable from '../components/CommodityTransactionTable.vue'

export default {
  title () {
    return `Asset Transaction History`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    vSelect,
    CommodityTransactionTable,
    PlusCircleIcon,
    BSpinner
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      showTable: true,
      filter: {
        user_id: '',
        commodity_slug: '',
        warehouse_slug: '',
      },
      formPayload: {
        type: 'add',
        user_id: null,
        commodity_slug: '',
        warehouse_slug: '',
        amount: 0,
        note: '',
      },
      isLoading: false,
      warehouses: [],
      commodities: [],
      users: [],
      units: Object,
    }
  },
  created() {
    this.loadFormData();
    this.executeSearch('')
  },
  methods: {
    loadFormData() {
      
      this.$http.get('/public/commodities/warehouses')
      .then(response => {
        this.warehouses = response.data.data
      })
      this.$http.get('/public/commodities/')
      .then(response => {
        var context = this;
        this.commodities = response.data.data
        this.commodities.forEach((commodity) => {
            context.units[commodity.slug] = commodity.unit;
        });
      })

    },
    addDeductCommodity() {
      this.isLoading = true;
      this.$http.post('/admin/commodity-transactions/add', this.formPayload)
      .then(response => {

        this.reloadTable();
        this.$bvModal.hide('form-transaction')
        successNotification(this, 'Success', 'Transaction successfully created')

        this.cleanupForm();
        this.isLoading = false;

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)   
        }
        this.isLoading = false;
      })
    },
    cleanupForm() {
      this.formPayload = {
        type: 'add',
        user_id: null,
        commodity_slug: '',
        warehouse_slug: '',
        amount: 0,
        note: '',
      }
    },
    reloadTable() {
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + escape(query))
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.name + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(escape(search))
      loading(false);
    }, 300),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>